@import 'global/normalize';
@import 'global/elevation';

$color-primary: #009ba4;
$color-primary-darker: #007980;
$color-primary-lighter: #00DAE6;
$white: #fff;

$hover-transition: 280ms cubic-bezier(.25,.8,.25,1);

body {
    height: 100vh;
    margin: 0;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background: $color-primary;
    background: -webkit-gradient(linear, left top, left bottom, from($color-primary-darker), to($color-primary-lighter));    
    display: flex;
    align-items: flex-start;
    justify-content: center;

    font-family: sans-serif;
    position: relative;
}

.modul-logo {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: $white;
    padding: 10px;
    // box-shadow: 5px 5px 8px rgba(0,0,0,0.35);

    @include box-shadow(8);

    @media (max-height: 550px) {
        display: none;
    }

    @media (max-width: 400px) {
        left: 50%;
        transform: translateX(-50%);
    }

    img {
        width: 250px;
    }
}

.info-box {
    flex: 0 0 auto;
    margin-top: 25vh;
    max-width: 270px;
    width: 100%;
    // box-shadow: 5px 5px 8px rgba(0,0,0,0.35);
    padding: 30px;
    background-color: $white;
    // border-radius: 3px;
    display: flex;
    flex-direction: column;

    @include box-shadow(8);
    &__404 {
        color: $color-primary;
        font-weight: bold;
        font-size: 32pt;
        text-align: center;
        margin-bottom: 1rem;
    }

    &__text {
        text-align: center;
        margin-bottom: 1rem;
    }
    
    button {
        width: 100%;
        background: $color-primary;
        border: none;
        color: $white;
        padding: 0.5rem;
        transition: all $hover-transition;
        // box-shadow: 2.5px 2.5px 4px rgba(0,0,0,0.35);
        cursor: pointer;
        @include box-shadow(2);
        &:hover {
            transform: scale(1.02);
            transition: 0.25s;
            @include box-shadow(8);
        }        
    }
}

